export function sk_wt_add_decription() {
    document.querySelector('#sk-wt-menu .sk_wt_callback_form').addEventListener('click', (e) => {
        if (e.target.matches('.wp-element-button')) {
            const formGroups = Array.from(document.querySelectorAll('#sk-wt-menu .sk_wt_callback_form .form_group'))
            formGroups.forEach(item => {
                item.classList.toggle('closed')
            })
        }
    }, { once: true })
}

export function sk_wt_menu_click_handler(event) {

    if(!document.querySelector('main#sk-angebotsformular-content')) {

        if (
            event.target.matches('#sk-offer *') ||
            event.target.matches('#sk-offer') ||
            event.target.matches('#sk-offer-mobile a') ||
            event.target.matches('#sk-offer-mobile')
        ) {
            // Prevent default link behaviour
            event.preventDefault()
    
            // Close the main menu
            if(document.querySelector('.nav_sub_menu .active')) {
                document.querySelector('.nav_sub_menu .active').classList.remove('active')
            }
            document.body.classList.remove('menu-open')
            
            // Toggle the WT Menu
            document.body.classList.toggle('wt-menu-open')
    
            if(event.target.matches('#sk-offer-mobile a') || event.target.matches('#sk-offer-mobile')) {
                document.querySelector('#sk-offer-mobile a').innerHTML = document.body.matches('.wt-menu-open') ? wp.i18n.__('Schließen', 'sk-pharma') : wp.i18n.__('Work together', 'sk-pharma')
            }
        }
    }
}