import { __ } from '@wordpress/i18n';

// Function to modify Filters
export function sk_karriere_filter() {
    if (document.querySelector('form.personio-position-filter')) {
        const filterContainer = document.querySelector('form.personio-position-filter ul')
        const filterEl = document.createElement('li')
        filterEl.innerHTML = `<a href="${__('/karriere', 'sk-pharma')}" class="personio-position-filter-reset">${__('Alle', 'sk-pharma')}</a>`

        filterContainer.prepend(filterEl)
    }

    if (document.querySelector('.wp-block-wp-personio-integration-list')) {
        const attrContainer = document.querySelectorAll('.wp-block-wp-personio-integration-list article.personioposition')

        attrContainer.forEach(container => {
            const attrWrapper = container.getElementsByTagName('p')
            const attrEls = attrWrapper[0].innerText.split(",")
            const posTitle = container.getElementsByTagName('a')[0]

            if (posTitle.innerText.includes('Initiativ')) {
                container.innerHTML = `
                    <div class="sk_stelle_attr bereich initiativ">${__('Initiativ','sk-pharma')}</div>
                    <h2 class="entry-title"><a href="${posTitle.href}">${posTitle.innerText.replace('(m/w/d)', '')}</a></h2>
                    <div class="sk_stelle_attr details">
                        <p>${__('Nichts passendes dabei? Dann bewirb dich initiativ – wir sind immer auf der Suche nach motivierten Talenten!', 'sk-pharma')}</p>
                    </div>
                    <div class="wp-block-button sk_stelle_link"><a class="wp-element-button sk_button_outline" href="${posTitle.href}">${__('Mehr Infos', 'sk-pharma')}</a></div>
                `
            } else {

                // Wenn 2 Standorte vorhanden sind
                if (attrEls.length === 4) {
                    container.innerHTML = `
                        <div class="sk_stelle_attr bereich ${attrEls[1].trim().toLowerCase()}">${attrEls[1].trim()}</div>
                        <div class="sk_stelle_attr mwd">${posTitle.innerText.includes('(m/w/d)') ? 'm | w | d' : ''}</div>
                        <h2 class="entry-title"><a href="${posTitle.href}">${posTitle.innerText.replace('(m/w/d)', '')}</a></h2>
                        <div class="sk_stelle_attr details">
                            <span class="anstellung">${attrEls[2].trim()}, ${attrEls[3].trim()}</span>
                            <span class="location">${attrEls[0].trim()}</span>
                        </div>
                        <div class="wp-block-button sk_stelle_link"><a class="wp-element-button sk_button_outline" href="${posTitle.href}">${__('Mehr Infos', 'sk-pharma')}</a></div>
                    `
                }
                // Wenn 2 Standorte vorhanden sind
                if (attrEls.length === 5) {
                    container.innerHTML = `
                        <div class="sk_stelle_attr bereich ${attrEls[2].trim().toLowerCase()}">${attrEls[2].trim()}</div>
                        <div class="sk_stelle_attr mwd">${posTitle.innerText.includes('(m/w/d)') ? 'm | w | d' : ''}</div>
                        <h2 class="entry-title"><a href="${posTitle.href}">${posTitle.innerText.replace('(m/w/d)', '')}</a></h2>
                        <div class="sk_stelle_attr details">
                            <span class="anstellung">${attrEls[3].trim()}, ${attrEls[4].trim()}</span>
                            <span class="location">${attrEls[0].trim()} ${__('oder', 'sk-pharma')} ${attrEls[1].trim()}</span>
                        </div>
                        <div class="wp-block-button sk_stelle_link"><a class="wp-element-button sk_button_outline" href="${posTitle.href}">${__('Mehr Infos', 'sk-pharma')}</a></div>
                    `
                }

            }

            container.classList.add('loaded')
        })
    }

    if (document.querySelector('.personio-position-filter-without-reload .personio-position-filter-reset')) {
        document.querySelector('.personio-position-filter-without-reload .personio-position-filter-reset').addEventListener('click', e => {
            e.preventDefault()
            document.querySelector('.personio-filter-selected').classList.remove('personio-filter-selected')
        })
    }
}