let pos = {
    start: 0,
    end: 0
}

export function sk_add_slider_navigation() {
    Array.from(document.querySelectorAll('.sk_slider_frame .sk_row:has(.sk_slider_slide)')).forEach(slider => {

        if (slider.childElementCount > 1) {

            const sliderItemsCount = slider.childElementCount

            const buttonTextPrev = wp.i18n.__('Zurück', 'sk-pharma')
            const buttonTextNext = wp.i18n.__('Weiter', 'sk-pharma')

            const sliderNavigation = document.createElement('div')
            sliderNavigation.classList.add('sk_slider_navigation')
            sliderNavigation.innerHTML = `
                <button type="button" class="sk_slider_button prev"><span class="visually-hidden">${buttonTextPrev}</span></button>
                <button type="button" class="sk_slider_button next"><span class="visually-hidden">${buttonTextNext}</span></button>
            `

            const sliderBullets = document.createElement('ul')
            sliderBullets.classList.add('sk_slider_bullets')
            let sliderBulletsContent = ''
            for (let i = 1; i <= sliderItemsCount; i++) {
                sliderBulletsContent += '<li class="sk_slider_bullet" data-slide="' + i + '"><span class="visually-hidden">Slide ' + i + '</span></li>'
            }
            sliderBullets.innerHTML = sliderBulletsContent

            slider.appendChild(sliderBullets)
            slider.appendChild(sliderNavigation)

            slider.querySelector('.sk_slider_slide:first-child').classList.add('active')
            slider.querySelector('.sk_slider_bullet:first-child').classList.add('active')
            slider.dataset.slide = 1

            slider.addEventListener('click', e => {

                let currentSlide = parseInt(slider.dataset.slide)

                if (e.target.matches('.prev') && currentSlide > 1) {
                    slider.dataset.slide = currentSlide - 1
                }
                
                if (e.target.matches('.prev') && currentSlide === 1) {
                    slider.dataset.slide = sliderItemsCount
                }

                if (e.target.matches('.next') && currentSlide < sliderItemsCount) {
                    slider.dataset.slide = currentSlide + 1
                }
                
                if (e.target.matches('.next') && currentSlide == sliderItemsCount) {
                    slider.dataset.slide = 1
                }

                if (e.target.matches('.sk_slider_bullet')) {
                    slider.dataset.slide = e.target.dataset.slide
                }
            })

            // Start Swipe Logic
            slider.addEventListener('touchstart', (e) => {
                grabHandler(e, pos, slider)
            })
        }

        const slideObserver = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-slide') {
                    const newValue = slider.getAttribute('data-slide');

                    Array.from(slider.querySelectorAll('.sk_slider_slide')).forEach(slide => slide.classList.remove('active'))
                    slider.querySelector('.sk_slider_slide:nth-child(' + newValue + ')').classList.add('active')

                    Array.from(slider.querySelectorAll('.sk_slider_bullet')).forEach(bullet => {
                        if (parseInt(bullet.dataset.slide) <= parseInt(newValue)) {
                            bullet.classList.add('active')
                        } else {
                            bullet.classList.remove('active')
                        }
                    })
                }
            }
        })

        slideObserver.observe(slider, {
            attributes: true,
            attributeFilter: ['data-slide']
        })
    })
}

function grabHandler(e, pos, slider) {
    slider.style.cursor = 'grabbing'
    slider.style.userSelect = 'none'
    pos.start = e.touches[0].screenX
    document.addEventListener('touchmove', dragHandler)
    document.addEventListener('touchend', function stopDrag() {
        slider.style.removeProperty('cursor')
        slider.style.removeProperty('user-select')
        let move = pos.start - pos.end
        if(Math.abs(move) < 15) {
            return
        }
        if(move > 0) {
            slider.dataset.slide = (parseInt(slider.dataset.slide) < (slider.childElementCount - 2)) ? parseInt(slider.dataset.slide) + 1 :
            1
        } else {
            slider.dataset.slide = (parseInt(slider.dataset.slide) > 1) ? parseInt(slider.dataset.slide) - 1 : slider.childElementCount - 2
        }
    
        document.removeEventListener('touchmove', dragHandler)
        document.removeEventListener('touchend', stopDrag)
    })
}
function dragHandler(e) {
    pos.end = e.touches[0].screenX
}